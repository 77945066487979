const socialMediaLinks = {
    instagram: {
        url: "https://www.instagram.com/vancouverlive95/"
    },
    facebook:{
        url: "https://www.facebook.com/poprockvancouver/"
    },
    youtube:{
        url: "https://www.youtube.com/channel/UC5KcKy2GioVd-YewRBGKrAw"
    },
    linkaband:{
        url:"https://linkaband.com/vancouver"
    }
};


export default socialMediaLinks;