import React, {useEffect, useState} from "react";
import NavButtons, {PrevMobile, NextMobile} from "./NavButtons";

import API_PATH from "../config/API_PATH";
import axios from "axios";

import { getRandomNum } from "../config/functions";

import './css/videoGallery.css';

function VideoGalleryPlayer({src}){
    const videoId = src.replace("https://www.youtube.com/watch?v=", "");
    const embedURL = "https://www.youtube.com/embed/"+videoId;

    return(
        <iframe 
            className="video-gallery-player"
            src={embedURL}
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
        </iframe>);
}

function VideoGalleryInner(props){
    const itemList = props.itemList;
    const [currentItem, setCurrentItem] = useState(props.firstItem);

    function navClick(direction){
        let currExtNum;
        if(direction === "next" && itemList!=null){
            currExtNum = (currentItem + 1 >= itemList.length) ? 0 : (currentItem + 1);
            setCurrentItem(currExtNum);
        }
        if(direction === "prev" && itemList!=null){
            currExtNum = currentItem === 0 ? (itemList.length - 1) : (currentItem - 1);
            setCurrentItem(currExtNum);
        }
    }

    return(
        <>
            <div key={currentItem} className="video-item" >
                <div className="video-item-title">
                    <div className="video-item-title-cont">
                        <PrevMobile prev={()=>{
                            navClick("prev");
                            }} />
                        <h2>{itemList[currentItem].titre_display}</h2>
                        <NextMobile next={()=>{
                            navClick("next");
                            }} />
                    </div>
                </div>
                <div className="video-gallery-player-cont">
                     <VideoGalleryPlayer style={{backgroundColor: "black"}} src={itemList[currentItem].src} />
                </div>
            </div>
            <NavButtons 
                next={()=>{navClick("next")}} 
                prev={()=>{navClick("prev")}}/>
        </>
        );
}

export default function VideoGallery(){
    const [videoListe, setvideoListe] = useState(null);
    const [firstItem, setFirstItem] = useState(null);

    useEffect(()=>{
        getGallerie();
    },[]);

    function getGallerie(){
        axios.get(API_PATH + "extraits/read.php?t="+Date.now(), 
        {headers: 
            {'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'}
        })
        .then((res)=>{
            const numVideo = res.data.filter(item => item.type==="video").length;
            setvideoListe(res.data.filter(item => item.type==="video")); 
            setFirstItem(getRandomNum(0, numVideo));
        })
        .catch((err)=>console.log(err));
    }

    return(
        <div id="video-gallery-cont">
            {(videoListe && firstItem!=null) && <VideoGalleryInner itemList={videoListe} firstItem={firstItem} />}
        </div>
    );
}