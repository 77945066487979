import React, { useEffect, useState, useRef } from "react";

import axios from "axios";
import API_PATH from "../config/API_PATH";

import { getRandomNum } from "../config/functions";

import './css/galleryModal.css';

function ModalNavNext({ nextFunc }) {
    return (
        <button className="modal-nav-next desktop-only"
            onClick={(e) => {
                e.stopPropagation()
                nextFunc()
            }}>
            <svg
                className="modal-fleche-apres"
                xmlns="https://www.w3.org/2000/svg"
                xmlnsXlink="https://www.w3.org/1999/xlink"
                x={0}
                y={0}
                viewBox="0 0 43.2 43.2"
                style={{
                    enableBackground: "new 0 0 43.2 43.2",
                }}
                xmlSpace="preserve"
            >
                <style>
                    {
                        ".st2{clip-path:url(#SVGID_00000141453769898369360470000001075491958207130798_);fill:#fff}"
                    }
                </style>
                <circle
                    cx={21.6}
                    cy={21.6}
                    r={21.6}
                    style={{
                        fill: "#85a6c7",
                    }}
                />
                <path
                    d="M32 21.1 20.1 10.3c-.7-.6-1.7-.1-1.7.8v21.6c0 .9 1.1 1.4 1.7.8L32 22.6c.4-.4.4-1.1 0-1.5z"
                    style={{
                        fill: "#fff",
                    }}
                />
                <defs>
                    <circle id="SVGID_1_" cx={21.6} cy={21.6} r={21.6} />
                </defs>
                <clipPath id="SVGID_00000098218006422365579810000001654330493948540032_">
                    <use
                        xlinkHref="#SVGID_1_"
                        style={{
                            overflow: "visible",
                        }}
                    />
                </clipPath>
                <path
                    transform="rotate(-180 10 21.83)"
                    style={{
                        clipPath:
                            "url(#SVGID_00000098218006422365579810000001654330493948540032_)",
                        fill: "#fff",
                    }}
                    d="M-1.6 17.8h23.2v8H-1.6z"
                />
            </svg>
        </button>);
}

function ModalNavPrev({ prevFunc }) {
    return (
        <button className="modal-nav-prev desktop-only"
            onClick={(e) => {
                e.stopPropagation();
                prevFunc();
            }}>
            <svg
                className="modal-fleche-avant"
                xmlns="https://www.w3.org/2000/svg"
                xmlnsXlink="https://www.w3.org/1999/xlink"
                x={0}
                y={0}
                viewBox="0 0 43.2 43.2"
                style={{
                    enableBackground: "new 0 0 43.2 43.2",
                }}
                xmlSpace="preserve"
            >
                <style>
                    {
                        ".st2{clip-path:url(#SVGID_00000006694691285456510170000016654943228834743973_);fill:#fff}"
                    }
                </style>
                <circle
                    cx={21.6}
                    cy={21.6}
                    r={21.6}
                    style={{
                        fill: "#85a6c7",
                    }}
                />
                <path
                    d="M11.2 22.1 23 32.9c.7.6 1.7.1 1.7-.8V10.6c0-.9-1.1-1.4-1.7-.8L11.2 20.6c-.4.4-.4 1.1 0 1.5z"
                    style={{
                        fill: "#fff",
                    }}
                />
                <defs>
                    <circle id="SVGID_1_" cx={21.6} cy={21.6} r={21.6} />
                </defs>
                <clipPath id="SVGID_00000153688345238766781180000000010320588144589218_">
                    <use
                        xlinkHref="#SVGID_1_"
                        style={{
                            overflow: "visible",
                        }}
                    />
                </clipPath>
                <path
                    style={{
                        clipPath:
                            "url(#SVGID_00000153688345238766781180000000010320588144589218_)",
                        fill: "#fff",
                    }}
                    d="M21.6 17.4h23.2v8H21.6z"
                />
            </svg>
        </button>
    );
}

export default function GalleryModal({ open, closingFunc }) {
    const [currImg, setCurrImg] = useState(null);
    const [currImgHeight, setCurrImgHeight] = useState(null);
    const [currImgWidth, setCurrImgWidth] = useState(null);
    const [imgListe, setImgListe] = useState(null);
    const [anim, setAnim] = useState(null);

    const imgRef = useRef(null);

    useEffect(() => {
        axios.get(API_PATH + "gallerie/read.php?t="+Date.now(), 
        {headers: 
            {'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'}
        })
            .then((res) => {
                const numImg = res.data.length;
                const liste = res.data.map((item) => {
                    const extraClass = imgClass(item.src);
                    return {
                        id: item.id,
                        legende: item.legende,
                        src: item.src,
                        extraClass: extraClass
                    }
                });
                setImgListe(liste);
                setCurrImg(getRandomNum(0, numImg));
            })
            .catch((err) => console.log(err));
    }, []);

    function imgClass(src) {
        const img = new Image();
        img.src = src;

        img.onload = function(){
            
            setCurrImgHeight(img.naturalHeight);
            setCurrImgWidth(img.naturalWidth);
            
        }
        
        let animClass = " ";

        const sizeClass = (currImgWidth >= currImgHeight) ? "gallery-img-landscape" : "gallery-img-portrait";

        if (anim != null) {
            animClass += (anim === "next") ? "rtl-mobile" : "ltr-mobile";
        }

        return sizeClass + animClass;
    }

    function modalNavClick(nav) {
        const numListe = imgListe.length;
        const currItem = currImg;
        let nextItem = null;
        if (nav === "next") {
            nextItem = (currItem + 1 >= numListe) ? 0 : currItem + 1;
            setAnim("next");
        }
        else if (nav === "prev") {
            nextItem = (currItem === 0) ? numListe - 1 : currItem - 1;
            setAnim("prev");
        }

        setCurrImg(nextItem);
    }

    return (
        <>
            {(imgListe != null && currImg != null) &&
                <div
                    style={(open) ? { display: "flex" } : { display: "none" }}
                    onClick={() => {
                        setAnim(null);
                        closingFunc()
                    }}
                    className="gallery-modal-outer">
                    <div
                        className="gallery-modal-cont">
                        <div
                            className="modal-img-outer-cont">
                            <div
                                onClick={(e)=>e.stopPropagation()} 
                                className="gallery-mobile-legende">
                                <h5
                                    key={imgListe[currImg].id}
                                    onClick={(e) => { e.stopPropagation() }}
                                    className="gallery-legend">
                                    {imgListe[currImg].legende}
                                </h5>
                                <button
                                    onClick={() => {
                                        setAnim(null);
                                        closingFunc()
                                    }}
                                    className="gallery-mobile-close">
                                    <img src="https://media.vancouver-live.fr/site/medias/Bouton_fermeture.png" alt="bouton de fermeture de la gallerie" />
                                </button>
                            </div>
                            <ModalNavPrev prevFunc={() => modalNavClick("prev")} />
                            <div className="modal-img-inner-cont">
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        modalNavClick("prev");
                                    }}
                                    className="mobile-gallery-nav mobile-gallery-nav-prev"></div>
                                <img
                                    ref={imgRef}
                                    key={imgListe[currImg].id}
                                    src={imgListe[currImg].src}
                                    alt={imgListe[currImg].legende}
                                    onClick={(e) => { e.stopPropagation() }}
                                    className={imgClass(imgListe[currImg].src)}
                                />
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        modalNavClick("next");
                                    }}
                                    className="mobile-gallery-nav mobile-gallery-nav-next"></div>
                            </div>
                            <ModalNavNext nextFunc={() => modalNavClick("next")} />
                        </div>
                        <h5
                            key={imgListe[currImg].id}
                            onClick={(e) => { e.stopPropagation() }}
                            className="gallery-legend desktop-only">
                            {imgListe[currImg].legende}
                        </h5>
                    </div>
                </div>}
        </>
    )
}