import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

/* PAGES */
import HomePage from './pageComponents/HomePage';
import GroupePage from './pageComponents/GroupePage';
import ContactPage from './pageComponents/ContactPage';
import SetlistPage from './pageComponents/SetlistPage';
import MediasPage from './pageComponents/MediasPage';

/* COMPONENTS */
import Header from './components/Header';

/* CSS */
import './css/style.css';

function App() {
  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/groupe" element={<GroupePage />} />
          <Route path="/setlist" element={<SetlistPage />} />
          <Route path="/medias" element={<MediasPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </main>
    </Router>
  );
}

export default App;
