import React, {useState} from "react";
import AudioPlayer from "./AudioPlayer";

import "./css/extraitModal.css";

function SimpleVideoPlayer({src}){
    const videoId = src.replace("https://www.youtube.com/watch?v=", "");
    const embedURL = "https://www.youtube.com/embed/"+videoId;

    return(
        <iframe 
            className="simple-video-player"
            src={embedURL}
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
        </iframe>);
}


export default function ExtraitModal(props){
    const [open, setOpen] = useState(false);
    const item = props.track;

    function closeModal(e){
        if(item.type === "audio"){
            const audio = e.target.getElementsByTagName("audio")[0];
            if(!audio.paused){
                audio.pause();
            }
            audio.currentTime = 0;
        } else if(item.type === "video"){
            const video = e.target.getElementsByTagName("iframe")[0];
            const source = video.src;
            video.src = source;
        }
        setOpen(false);
    }

    return(
        <>
            <button
                className="extrait-modal-button" 
                onClick={()=>{setOpen(true)}}>
                {item.type === "audio" && <>AUDIO</>}
                {item.type === "video" && <>VIDEO</>}
            </button>
            <div 
                className="modal-outer" 
                style={{display : open ? "flex" : "none" }}
                onClick={closeModal}>
                    <div className="modal-inner"
                         onClick={(e)=>{e.stopPropagation()}}>
                        {item.type === "audio" && <>
                            <AudioPlayer src={item.src} resetValue={open} />
                        </>}
                        {item.type === "video" && <>
                            {<SimpleVideoPlayer src={item.src} resetValue={open} />}
                        </>}
                    </div>
            </div>
        </>
    )
}