import React, {useEffect, useRef} from "react";
import {Link, useLocation} from 'react-router-dom';

import socialMediaLinks from "../content/socialMediaLinks";

import '../css/nav.css';

export default function Header(){
    const mobileMenu = useRef(null);
    const menuDraggable = useRef(null);

    const location = useLocation();
    const path = location.pathname;



    useEffect(()=>{
        const current = path.replace('/', '');
        const links = document.getElementById('nav-desktop').getElementsByTagName('a');
        const linksMobile = document.getElementById('mobile-menu-nav').getElementsByTagName('a');
        for(let i = 0; i < links.length; i++){
            if(links[i].id.replace('nav-', '') === current){
                links[i].classList = "nav-focus";
                linksMobile[i].classList = "nav-focus";
            }else{
                links[i].classList = "";
                linksMobile[i].classList = "";
            }
        }
    },[path]);


    function menuClose(){
        mobileMenu.current.style.transition="transform 0.4s";
        mobileMenu.current.style.transform="translateX(90%)";
        menuDraggable.current.className="mobile-default";
        menuDraggable.current.getElementsByTagName('h5')[0].innerHTML="MENU";
    }

    function menuOpen(){
        menuDraggable.current.className="mobile-open";
        menuDraggable.current.getElementsByTagName('h5')[0].innerHTML="";
        mobileMenu.current.style.transition="transform 0.4s";
        mobileMenu.current.style.transform="translateX(0px)";
    }


    function menuToggle(e){
        const screenWidth = window.innerWidth;
        if(getXOffset() >= (0.8 * screenWidth)){
            menuOpen();
        }else{
            menuClose();
        }
    }

    function getXOffset(){
		const style = getComputedStyle(mobileMenu.current);
		const matrix = new DOMMatrixReadOnly(style.transform);
   
		return matrix.e;
	}

    function getMenuMobileTitle(){
        const current = path.replace('/', '');
        switch(current){
            case '':
                return 'Actualité';
            case 'groupe':
                return 'Le Groupe';
            case 'setlist':  
                return 'Setlist'; 
            case 'medias':
                return 'Médias';
            case 'contact':
                return 'contact';    
            default:
                return 'erreur';       
        }
    }

    return(
        <header>
            <nav id="nav-desktop">
                <Link id="nav-groupe" to="/groupe">Le Groupe</Link>
                <Link id="nav-setlist" to="/setlist">Set List</Link>
                <Link id="nav-medias" to="/medias">Medias</Link>
                <Link id="nav-contact" to="/contact">Contact</Link>
            </nav>
            <nav id="social-media-desktop" className="desktop-only">
                <a
                    href={socialMediaLinks.facebook.url}
                    title="Notre page Facebook">
                    <svg
                        xmlns="https://www.w3.org/2000/svg"
                        width={30}
                        height={30}
                        xmlSpace="preserve"
                        id="fb-icon"
                    >
                        <path
                            d="M23.592 0H6.408A6.416 6.416 0 0 0 0 6.408v17.184A6.416 6.416 0 0 0 6.408 30h17.184A6.416 6.416 0 0 0 30 23.592V6.408A6.416 6.416 0 0 0 23.592 0zM27 23.592A3.412 3.412 0 0 1 23.592 27h-4.498c.007-2.783.005-5.566.005-8.349 0-.34.001-.341.342-.341.992 0 1.984-.006 2.976.004.234.002.304-.084.328-.311.078-.737.186-1.47.277-2.205.062-.501.108-1.005.176-1.505.02-.149-.06-.147-.152-.148-.262-.003-.525-.002-.787-.002-.959 0-1.919-.007-2.878.005-.233.003-.291-.075-.288-.296.011-.828-.015-1.657.012-2.484.017-.519.06-1.047.476-1.45.268-.26.595-.355.946-.416.399-.07.8-.043 1.2-.047.467-.004.935-.006 1.402.001.171.003.253-.042.252-.233a372.97 372.97 0 0 1-.001-3.345c0-.178-.074-.24-.25-.25-1.078-.058-2.156-.122-3.236-.094-1.213.031-2.346.333-3.308 1.101-.874.699-1.394 1.63-1.651 2.714a7.903 7.903 0 0 0-.196 1.697c-.015.934-.01 1.869 0 2.804.003.221-.055.299-.288.296-1.008-.012-2.017-.006-3.025-.005-.318 0-.331.014-.332.336v3.515c0 .297.018.315.327.316 1.017.001 2.033.005 3.05-.003.202-.002.268.06.267.265-.005 2.809-.005 5.619.001 8.428H6.408A3.41 3.41 0 0 1 3 23.592V6.408A3.412 3.412 0 0 1 6.408 3h17.184A3.412 3.412 0 0 1 27 6.408v17.184z"
                        />
                    </svg>
                </a>
                <a 
                    href={socialMediaLinks.instagram.url}
                    title="Notre page Instagram">
                    <svg
                        xmlns="https://www.w3.org/2000/svg"
                        width={30}
                        height={30}
                        xmlSpace="preserve"
                        id="insta-icon"
                    >
                        <path
                            d="M23.592 30H6.408A6.416 6.416 0 0 1 0 23.592V6.408A6.416 6.416 0 0 1 6.408 0h17.184A6.416 6.416 0 0 1 30 6.408v17.184A6.416 6.416 0 0 1 23.592 30zM6.408 3A3.412 3.412 0 0 0 3 6.408v17.184A3.412 3.412 0 0 0 6.408 27h17.184A3.412 3.412 0 0 0 27 23.592V6.408A3.412 3.412 0 0 0 23.592 3H6.408z"
                        />
                        <path
                            d="M15 22.955c-4.386 0-7.954-3.568-7.954-7.955 0-4.386 3.568-7.954 7.954-7.954s7.954 3.568 7.954 7.954c0 4.386-3.568 7.955-7.954 7.955zm0-12.909c-2.732 0-4.954 2.222-4.954 4.954s2.222 4.955 4.954 4.955 4.954-2.223 4.954-4.955-2.222-4.954-4.954-4.954z"
                        />
                        <circle  cx={22.958} cy={6.996} r={2} />
                    </svg>
                </a>
                <a 
                    href={socialMediaLinks.youtube.url}
                    title="Notre page Youtube">
                    <svg
                        xmlns="https://www.w3.org/2000/svg"
                        width={30}
                        height={30}
                        xmlSpace="preserve"
                        id="yt-icon"
                    >
                        <path
                            d="M23.592 30H6.408A6.416 6.416 0 0 1 0 23.592V6.408A6.416 6.416 0 0 1 6.408 0h17.184A6.416 6.416 0 0 1 30 6.408v17.184A6.416 6.416 0 0 1 23.592 30zM6.408 3A3.412 3.412 0 0 0 3 6.408v17.184A3.412 3.412 0 0 0 6.408 27h17.184A3.412 3.412 0 0 0 27 23.592V6.408A3.412 3.412 0 0 0 23.592 3H6.408z"
                        />
                        <path
                            d="M9.25 15.14V8.795c0-.584.027-.603.529-.353 4.313 2.154 8.626 4.309 12.937 6.466.124.062.317.094.325.249.009.192-.207.216-.339.282a10394.64 10394.64 0 0 1-12.937 6.467c-.48.239-.515.216-.515-.333V15.14z"
                        />
                    </svg>
                </a>
            </nav>
            <Link to="/" title="accueil">
                <div onClick={()=>menuClose()} draggable="false" id="banner-desktop"></div>
            </Link>
            <nav id="nav-mobile">
                <h2
                    onClick={()=>{window.location.reload();}}>
                    {getMenuMobileTitle()}
                </h2>
                <div id="mobile-menu" ref={mobileMenu}>
                    <div 
                        className="mobile-default" 
                        id="mobile-menu-open"
                        onClick={(e)=>{menuToggle(e);}}
                        ref={menuDraggable}
                        >
                        <h5 className="rotated">MENU</h5>
                    </div>
                    <div id="mobile-menu-nav">
                            <Link 
                                onClick={menuClose}
                                id="mobile-nav-groupe" 
                                to="/groupe">Le Groupe</Link>
                            <Link 
                                onClick={menuClose}
                                id="mobile-nav-setlist" 
                                to="/setlist">Set List</Link>
                            <Link 
                                onClick={menuClose}
                                id="mobile-nav-medias" 
                                to="/medias">Medias</Link>
                            <Link 
                                onClick={menuClose}
                                id="mobile-nav-contact" 
                                to="/contact">Contact</Link>
                    </div>
                    <div id="mobile-menu-close" onClick={menuClose}>
                        <img src="https://media.vancouver-live.fr/site/mobile/Menu_mobile_off.svg" alt="boutton de fermeture du menu"/>
                    </div>
                </div>
            </nav>
        </header>
    );
}