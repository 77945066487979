import React, {useState, useEffect} from "react";
import AudioPlayer from "./AudioPlayer";
import NavButtons, {PrevMobile, NextMobile} from "./NavButtons";
import axios from "axios";
import API_PATH from "../config/API_PATH";

import { getRandomNum } from "../config/functions";

import './css/extraits.css';

function ExtraitContent(props){
    const extrait = props.extrait;
    return(
        <div className="extrait-content">
            <AudioPlayer src={extrait.src} />
            <h3 className="extrait-artiste">{extrait.artiste}</h3>
            <h3 className="extrait-titre">{extrait.titre_db}</h3>
            <h3 className="extrait-annee">{extrait.annee}</h3>
        </div>
    );
}

export default function Extraits({additionalClasses = null}){
    const [extraitsListe, setExtraitsListe] = useState(null);
    const [currentExtract, setCurrentExtract] = useState(null);

    useEffect(()=>{
       getExtraits();
    },[])

    function getExtraits(){
        axios.get(API_PATH + "extraits/read.php?t="+Date.now(), 
        {headers: 
            {'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'}
        })
        .then((res)=>{
            const numExtraits = res.data.filter(item => item.type==="audio").length;
            setExtraitsListe(res.data.filter(item => item.type==="audio")); 
            setCurrentExtract(getRandomNum(0, numExtraits));
        })
        .catch((err)=>console.log(err));
    }

    function navClick(direction){
        let currExtNum;
        if(direction === "next" && extraitsListe!=null){
            currExtNum = (currentExtract + 1 >= extraitsListe.length) ? 0 : (currentExtract + 1);
            setCurrentExtract(currExtNum);
        }
        if(direction === "prev" && extraitsListe!=null){
            currExtNum = currentExtract === 0 ? (extraitsListe.length - 1) : (currentExtract - 1);
            setCurrentExtract(currExtNum);
        }
    }



    return(
        <div className="extrait-outer-cont">
            <section className={"extrait-cont "+additionalClasses}>
                <div id="extrait-k7">
                    <img src="https://media.vancouver-live.fr/site/charte/Extrait_K7.png" alt="Cassette audio" />
                </div>
                <div className="extrait-header">
                    <PrevMobile prev={()=>{navClick("prev")}} />
                    <h2>Extrait</h2>
                    <NextMobile next={()=>{navClick("next")}} />
                </div>
                {(extraitsListe && currentExtract!=null) && 
                    <ExtraitContent key={currentExtract} extrait={extraitsListe[currentExtract]} />
                }
            </section>
            <NavButtons next={()=>{navClick("next")}} prev={()=>navClick("prev")} />
        </div>
    );
}