import React from "react";
import ContactForm from "../components/ContactForm";

import MobileFooter from "../components/MobileFooter";

import '../css/contactPage.css';


export default function ContactPage(){
    return(
        <>
            <aside id="contact-page-aside" className="titre-page-aside desktop-only">
                <img src="https://media.vancouver-live.fr/site/contact/Contact_titre.png" alt="Titre de page Contact" />
            </aside>
            <ContactForm />
            <div id="contact-page-extra">
                <div id="contact-page-pdfs">
                    <h2>DOCS</h2>
                    <p>Plus de details sur nos prestations dans les documents à télécharger (en pdf).</p>
                    <a href="https://media.vancouver-live.fr/pdf/plaquetteVANCOUVER.pdf" title="Notre plaquette en PDF" download>Plaquette de presentation</a>
                    <a href="https://media.vancouver-live.fr/pdf/Fiche_technique_Vancouver.pdf" title="Notre fiche technique en PDF" download>Fiche technique</a>
                </div>
                <div id="contact-page-linkaband">
                    <a href="https://linkaband.com/vancouver" title="lien vers notre page Linkaband">
                        <div id="linkaband-text">
                            Prestations égalements disponibles via le site Linkaband
                        </div>
                        <img id="linkaband-logo-contact" src="https://media.vancouver-live.fr/site/contact/Verified_noir.png" alt="Logo linkaband" />
                    </a>
                </div>
            </div>
            <MobileFooter />
        </>
    );
}