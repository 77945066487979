import React from "react";

import "./css/photoGalleryButtons.css"



export function PhotoGalleryDesktop({openerFunc, open}){
    const extraClass = (open===true) ? " gallery-opened" : "";
    return(
        <button
            onClick={()=>openerFunc()} 
            className={"photo-gallery-desktop desktop-only" + extraClass}>
                <img id="gallery-photo-button-hover" src="https://media.vancouver-live.fr/site/medias/Album_photo_hover.png" alt="bouton d'ouverture (survol) de la gallerie" />
                <img id="photo-gallery-button" src="https://media.vancouver-live.fr/site/medias/Album_photo.png" alt="bouton d'ouverture de la gallerie"/>
        </button>
    );
}

export function PhotoGalleryMobile({openerFunc}){
    return(
        <button 
            onClick={()=>openerFunc()} 
            className="photo-gallery-mobile">
            <img src="https://media.vancouver-live.fr/site/medias/Album_photo_mobile.png" alt="bouton d'ouverture de la gallerie"/>
        </button>
    );
}