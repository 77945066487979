import React, {useEffect, useRef} from "react";
import './css/audioplayer.css'

/* PROPS : src(audio source) */
export default function AudioPlayer(props){
    const progressRef = useRef(null);
    const playerRef = useRef(null);
    const buttonRef = useRef(null);
    const outerButtonRef = useRef(null);
    const audioRef = useRef(null);
    const runtimeRef= useRef(null);

    const resetValue = (props.resetValue!=null) ? props.resetValue : null;

    useEffect(()=>{
        audioRef.current.volume=0.5;
    },[]);

    useEffect(()=>{
        if(!resetValue){
            if(outerButtonRef.current.classList.contains("audio-play")){
                outerButtonRef.current.classList.replace("audio-play", "audio-stop");
            }else if(outerButtonRef.current.classList.contains("audio-ended")){
                outerButtonRef.current.classList.replace("audio-ended", "audio-stop");
            }
            if(runtimeRef.current.classList.contains("runtime-playing")){
                runtimeRef.current.classList.replace("runtime-playing", "runtime-stopped");
            }
            buttonRef.current.src="https://media.vancouver-live.fr/site/player/icon_play.png";
            audioRef.current.currentTime=0;
            audioRef.current.pause();
        }
    }, [resetValue])

    function startStop(e){
        e.preventDefault();
        if(e.currentTarget.classList.contains("audio-stop")){
            e.currentTarget.classList.replace("audio-stop", "audio-play");
            runtimeRef.current.classList.replace("runtime-stopped", "runtime-playing");
            buttonRef.current.src="https://media.vancouver-live.fr/site/player/icon_pause.png";
            audioRef.current.play();
        }else if(e.currentTarget.classList.contains("audio-play")){
            e.currentTarget.classList.replace("audio-play", "audio-stop");
            runtimeRef.current.classList.replace("runtime-playing", "runtime-stopped");
            buttonRef.current.src="https://media.vancouver-live.fr/site/player/icon_play.png";
            audioRef.current.pause();
        }else if(e.currentTarget.classList.contains("audio-end")){
            e.currentTarget.classList.replace("audio-end", "audio-play");
            runtimeRef.current.classList.replace("runtime-stopped", "runtime-playing");
            buttonRef.current.src="https://media.vancouver-live.fr/site/player/icon_pause.png";
            audioRef.current.currentTime=0;
            audioRef.current.play();
        }
    }

    function updateTime(e){
        const current = Math.floor(e.target.currentTime);
        const duration = Math.floor(e.target.duration);

        const elapsed = duration - current;

        const min = Math.floor(elapsed / 60);
        const sec = ((elapsed % 60) > 9)? (elapsed % 60) : "0"+(elapsed % 60);

        console.log();

        runtimeRef.current.innerHTML = "- " + min + ":" + sec;

        const progress = (current / duration) * 100;

        if(progress === 100){
            outerButtonRef.current.classList.replace("audio-play", "audio-end");
            runtimeRef.current.classList.replace("runtime-playing", "runtime-stopped");
            buttonRef.current.src="https://media.vancouver-live.fr/site/player/icon_repeat.png";
        }

        progressRef.current.style.left=progress+"%";
    }

    function skipAudio(e){
        const playerRect = e.target.getBoundingClientRect();
        const playerPosX = Math.floor(playerRect['x']);
        const playerWidth = Math.floor(playerRect['width']);
        const pointerDownX = Math.floor(e.clientX);

        const pointerPosPercent = (pointerDownX - playerPosX) / playerWidth;

        const skipTo = Math.floor(audioRef.current.duration * pointerPosPercent);
        
        audioRef.current.currentTime = skipTo;
    }

    
    return (
        <div 
            className="audio-player"
            ref={playerRef}>
            <button
                onClick={startStop}
                ref={outerButtonRef}
                className="audio-button audio-stop">
                    <img 
                        ref={buttonRef}
                        alt="Bouton start/stop du lecteur audio"
                        src="https://media.vancouver-live.fr/site/player/icon_play.png" />
            </button>
            <audio
                ref={audioRef}
                src={props.src}
                onTimeUpdate={updateTime}
                className="audio-source"></audio>
            <div
                onPointerDown={skipAudio}
                className="audio-progress-cont">
                <div className="audio-progress">
                    <div
                        ref={progressRef}
                        className="audio-progress-indicator">
                    </div>
                </div>
            </div>
            <h5 ref={runtimeRef} className="audio-runtime runtime-stopped">0:00</h5>
        </div>);
}