import React, {useEffect, useState} from "react";
import Setlist from "../components/Setlist";
import Vinyl from "../components/Vinyl";
import Zoom from "../components/Zoom";

import MobileFooter from "../components/MobileFooter";

import axios from "axios";
import API_PATH from "../config/API_PATH";
import { getRandomNum } from "../config/functions";

/* TEST VERSION */

export default function SetlistPage(){
    const [zoomList, setZoomList] = useState(null);
    const [zoomElem, setZoomElem] = useState(null);

    function getZoomList() {
       axios.get(API_PATH + "zoom/read.php?t="+Date.now(), 
        {headers: 
            {'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'}
        })
            .then((res) => {
                setZoomList(res.data);
            }).catch(err => console.log(err));
    }

    function newZoomElem(){
        if(zoomList !== null){
            let i;
            do{
               i = getRandomNum(0, zoomList.length);            
            }while(i === zoomList.indexOf(zoomElem));
            setZoomElem(zoomList[i]);
        }
    }

    useEffect(()=>{
        getZoomList();
    },[]);

   useEffect(()=>{
        newZoomElem();
    },[zoomList]);

    return(
        <>
            <Vinyl />
            <Setlist switchZoom={()=>{newZoomElem();}}/>
            <Zoom item={zoomElem} />
            <MobileFooter />
        </>
    );
}