import React, { useState, useRef } from "react";

import axios from "axios";

import "./css/contactForm.css";

export default function ContactForm() {
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [tel, setTel] = useState("");
    const [email, setEmail] = useState("");
    const [sujet, setSujet] = useState("");
    const [message, setMessage] = useState("");

    const telRef = useRef(null);
    const emailRef = useRef(null);
    const sujetRef = useRef(null);
    const messageRef = useRef(null);

    const confirmRef = useRef(null);

    function createContactObject() {
        return {
            nom: nom,
            prenom: prenom,
            tel: tel,
            email: email,
            sujet: sujet,
            message: message
        };
    }

    function validateForm(){
        let valid = true;
        /*Validation Tel si tel entré*/
        if(tel.replace(" ", "") !== ""){
            if(tel.length < 10 || !tel.startsWith("0")){
                if(!tel.startsWith("+")){
                    telRef.current.classList.add("form-incorrect");
                    valid = false;
                }
            }
        }
        /* VALIDATION EMAIL */
        const atPos = email.indexOf("@");
        const hasSpaces = (email.indexOf(" ") === -1)? false : true;
        const lastPos = email.length-1;
        if(atPos <= 0 || email.slice(atPos, lastPos).indexOf(".") < 1 || hasSpaces){
            emailRef.current.classList.add("form-incorrect");
            valid = false;
        }
        if(sujet.length === 0){
            sujetRef.current.classList.add("form-incorrect");
            valid = false;
        }
        if(message.length === 0){
            messageRef.current.classList.add("form-incorrect");
            valid = false;
        }

        return valid;
    }

    function clearErrors(){
        const errors = document.getElementsByClassName("form-field");
        for (let i=0; i < errors.length; i++){
            if(errors[i].classList.contains("form-incorrect")){
                errors[i].classList.remove("form-incorrect");
            }
        }
    }

    function clearForm() {
        setNom("");
        setPrenom("");
        setTel("");
        setEmail("");
        setSujet("");
        setMessage("");
    }


    function handleSubmit(e) {
        e.preventDefault();
        clearErrors();
        if(validateForm()) {
            axios.post("https://api.vancouver-live.fr/contact/contact.php", createContactObject())
                .then(() => {
                    clearForm();
                    confirmRef.current.innerHTML="Message envoyé !";
                        setTimeout(()=>{
                            confirmRef.current.innerHTML="Envoyer";
                        }, 4000);
                    }).catch(err => console.log(err));
        }
    }

    return (
        <div id="contact-form-cont">
            <p id="form-intro">
                    Une question ? Une suggestion ? Besoin d’un devis pour une prestation ? Merci de nous contacter via le formulaire ci-dessous. 
                    Vous trouverez également des informations supplémentaires et toutes nos coordonnées de contact en <strong>
                    <a href="https://media.vancouver-live.fr/pdf/PlaquetteVANCOUVER.pdf" 
                    style={{all: "unset", cursor: "pointer"}} title="Notre plaquette en PDF" download>
                        téléchargeant notre plaquette.
                    </a>
                </strong>
                <span>(* champs obligatoires)</span>
            </p>
            <div id="contact-form">
                <div className="form-row">
                    <div className="form-field">
                        <label htmlFor="form-nom">Nom</label>
                        <input
                            onChange={(e) => setNom(e.target.value)}
                            value={nom}
                            autoComplete="off"
                            id="form-nom" type="text" />
                    </div>
                    <div className="form-field">
                        <label htmlFor="form-prenom">Prénom</label>
                        <input
                            onChange={(e) => setPrenom(e.target.value)}
                            value={prenom}
                            autoComplete="off"
                            id="form-prenom" type="text" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-field" ref={telRef}>
                        <label htmlFor="form-tel">Téléphone</label>
                        <input
                            onChange={(e) => setTel(e.target.value)}
                            value={tel}
                            autoComplete="off"
                            id="form-tel" type="tel" />
                    </div>
                    <div className="form-field" ref={emailRef}>
                        <label htmlFor="form-email">Email*</label>
                        <input
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                            autoComplete="off"
                            id="form-email" type="email" />
                    </div>
                </div>
                <div className="form-block">
                    <div className="form-col">
                        <div className="form-field" ref={sujetRef}>
                            <label htmlFor="form-sujet">Sujet*</label>
                            <input
                                onChange={(e) => setSujet(e.target.value)}
                                value={sujet}
                                required
                                autoComplete="off"
                                id="form-sujet" type="text" />
                        </div>
                        <div className="form-field form-textarea" ref={messageRef}>
                            <label
                                style={{display: "block"}} 
                                htmlFor="form-message">Votre message*</label>
                            <textarea
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                                required
                                autoComplete="off"
                                id="form-message"></textarea>
                        </div>
                    </div>
                    <div
                        onClick={handleSubmit} 
                        className="form-field form-submit">
                        <svg
                            className="form-submit-arrow"
                            xmlns="https://www.w3.org/2000/svg"
                            xmlnsXlink="https://www.w3.org/1999/xlink"
                            x={0}
                            y={0}
                            viewBox="0 0 43.2 43.2"
                            style={{
                                enableBackground: "new 0 0 43.2 43.2",
                            }}
                            xmlSpace="preserve"
                        >
                            <style>
                                {
                                    ".st2{clip-path:url(#SVGID_00000141453769898369360470000001075491958207130798_);fill:#fff}"
                                }
                            </style>
                            <circle
                                cx={21.6}
                                cy={21.6}
                                r={21.6}
                                style={{
                                    fill: "#85a6c7",
                                }}
                            />
                            <path
                                d="M32 21.1 20.1 10.3c-.7-.6-1.7-.1-1.7.8v21.6c0 .9 1.1 1.4 1.7.8L32 22.6c.4-.4.4-1.1 0-1.5z"
                                style={{
                                    fill: "#fff",
                                }}
                            />
                            <defs>
                                <circle id="SVGID_1_" cx={21.6} cy={21.6} r={21.6} />
                            </defs>
                            <clipPath id="SVGID_00000098218006422365579810000001654330493948540032_">
                                <use
                                    xlinkHref="#SVGID_1_"
                                    style={{
                                        overflow: "visible",
                                    }}
                                />
                            </clipPath>
                            <path
                                transform="rotate(-180 10 21.83)"
                                style={{
                                    clipPath:
                                        "url(#SVGID_00000098218006422365579810000001654330493948540032_)",
                                    fill: "#fff",
                                }}
                                d="M-1.6 17.8h23.2v8H-1.6z"
                            />
                        </svg>
                        <h3 className="submit-text" ref={confirmRef}>Envoyer</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}