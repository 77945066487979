import React from "react";
import './css/vinyl.css'

export default function Vinyl(props){
    return(
        <div id="setlist-vinyl">
           <div id="setlist-vinyl-disc"></div>
           <div id="setlist-vinyl-cover"></div>
        </div>
    );
}