import React from "react";
import './css/navButtons.css';

/* A fournir en props : 
        une fonction 'next' qui permet d'aller à l'element suivant
        une fonction 'prev' qui permet d'aller a l'element precendent*/

export default function NavButtons(props){
    return(
        <div className="nav-buttons">
                <button className="nav-buttons-prev" onClick={() => props.prev()}>
                    <svg
                        className="fleche-avant"
                        xmlns="https://www.w3.org/2000/svg"
                        xmlnsXlink="https://www.w3.org/1999/xlink"
                        x={0}
                        y={0}
                        viewBox="0 0 43.2 43.2"
                        style={{
                            enableBackground: "new 0 0 43.2 43.2",
                        }}
                        xmlSpace="preserve"
                    >
                        <style>
                            {
                                ".st2{clip-path:url(#SVGID_00000006694691285456510170000016654943228834743973_);fill:#fff}"
                            }
                        </style>
                        <circle
                            cx={21.6}
                            cy={21.6}
                            r={21.6}
                            style={{
                                fill: "#85a6c7",
                            }}
                        />
                        <path
                            d="M11.2 22.1 23 32.9c.7.6 1.7.1 1.7-.8V10.6c0-.9-1.1-1.4-1.7-.8L11.2 20.6c-.4.4-.4 1.1 0 1.5z"
                            style={{
                                fill: "#fff",
                            }}
                        />
                        <defs>
                            <circle id="SVGID_1_" cx={21.6} cy={21.6} r={21.6} />
                        </defs>
                        <clipPath id="SVGID_00000153688345238766781180000000010320588144589218_">
                            <use
                                xlinkHref="#SVGID_1_"
                                style={{
                                    overflow: "visible",
                                }}
                            />
                        </clipPath>
                        <path
                            style={{
                                clipPath:
                                    "url(#SVGID_00000153688345238766781180000000010320588144589218_)",
                                fill: "#fff",
                            }}
                            d="M21.6 17.4h23.2v8H21.6z"
                        />
                    </svg>
                </button>
                <button className="nav-buttons-next" onClick={() => props.next()}>
                    <svg
                        className="fleche-apres"
                        xmlns="https://www.w3.org/2000/svg"
                        xmlnsXlink="https://www.w3.org/1999/xlink"
                        x={0}
                        y={0}
                        viewBox="0 0 43.2 43.2"
                        style={{
                            enableBackground: "new 0 0 43.2 43.2",
                        }}
                        xmlSpace="preserve"
                    >
                        <style>
                            {
                                ".st2{clip-path:url(#SVGID_00000141453769898369360470000001075491958207130798_);fill:#fff}"
                            }
                        </style>
                        <circle
                            cx={21.6}
                            cy={21.6}
                            r={21.6}
                            style={{
                                fill: "#85a6c7",
                            }}
                        />
                        <path
                            d="M32 21.1 20.1 10.3c-.7-.6-1.7-.1-1.7.8v21.6c0 .9 1.1 1.4 1.7.8L32 22.6c.4-.4.4-1.1 0-1.5z"
                            style={{
                                fill: "#fff",
                            }}
                        />
                        <defs>
                            <circle id="SVGID_1_" cx={21.6} cy={21.6} r={21.6} />
                        </defs>
                        <clipPath id="SVGID_00000098218006422365579810000001654330493948540032_">
                            <use
                                xlinkHref="#SVGID_1_"
                                style={{
                                    overflow: "visible",
                                }}
                            />
                        </clipPath>
                        <path
                            transform="rotate(-180 10 21.83)"
                            style={{
                                clipPath:
                                    "url(#SVGID_00000098218006422365579810000001654330493948540032_)",
                                fill: "#fff",
                            }}
                            d="M-1.6 17.8h23.2v8H-1.6z"
                        />
                    </svg>
                </button>
            </div>
    );
}

/*Takes a 'prev' function as a prop */
export function PrevMobile(props) {
    return (
        <button onClick={() => props.prev()} className="nav-mobile">
            <svg
                className="prev-mobile"
                xmlns="https://www.w3.org/2000/svg"
                x={0}
                y={0}
                viewBox="0 0 19.8 20"
                style={{
                    enableBackground: "new 0 0 19.8 20",
                }}
                xmlSpace="preserve"
            >
                <style>{".st5{fill:#fff}"}</style>
                <path
                    className="st0"
                    d="M12.4 6.7V1.6c0-.6-.4-1.2-.9-1.4-.6-.3-1.3-.2-1.8.2L.5 8.9c-.3.3-.5.7-.5 1.1 0 .4.2.9.5 1.1l9.2 8.5c.3.3.7.4 1 .4.2 0 .4 0 .6-.1.6-.3.9-.8.9-1.4v-4.3h7.4v-1.5h-8.9l-.1 5.8L1.5 10l9.3-8.4v6.6h8.9V6.7h-7.3z"
                />
                <path className="st0" d="M10.8 9.7h9v1.5h-9z" />
            </svg>
        </button>);
}

/*Takes a 'next' function as a prop */
export function NextMobile(props) {
    return (
        <button onClick={() => props.next()} className="nav-mobile">
            <svg
                className="next-mobile"
                xmlns="https://www.w3.org/2000/svg"
                x={0}
                y={0}
                viewBox="0 0 19.8 20"
                style={{
                    enableBackground: "new 0 0 19.8 20",
                }}
                xmlSpace="preserve"
            >
                <style>{".st0{fill:#fff}"}</style>
                <path
                    className="st0"
                    d="M7.4 13.3v5.1c0 .6.4 1.2.9 1.4.6.3 1.2.1 1.7-.3l9.2-8.5c.3-.3.5-.7.5-1.1 0-.4-.2-.9-.5-1.1L10 .4C9.7.1 9.3 0 9 0c-.3 0-.5 0-.7.1-.5.3-.9.8-.9 1.5v4.3H0v1.5h8.9L9 1.5l9.2 8.5-9.3 8.4v-6.6H0v1.5h7.4z"
                />
                <path className="st0" d="M0 8.8h9v1.5H0z" />
            </svg>
        </button>);
}