import React from "react";
import Extraits from "../components/Extraits";
import HomeGallery from "../components/HomeGallery";

import MobileFooter from "../components/MobileFooter";


export default function HomePage(){
    return(
        <>
            <aside id="home-page-aside" className="titre-page-aside desktop-only">
                <img src="https://media.vancouver-live.fr/site/accueil/Accueil_titre.svg" alt="Titre de page Actualité"/>
            </aside>
            <HomeGallery />
            <Extraits />
            <MobileFooter homepage="true" />
        </>
    );
}