import React, {useEffect, useState} from "react";
import NavButtons, {PrevMobile, NextMobile} from "./NavButtons";

import API_PATH from "../config/API_PATH";
import axios from "axios";

import './css/homeGallerie.css';

function HomeGalleryInner(props){
    const itemList = props.itemList;
    const [currentItem, setCurrentItem] = useState(0);

    const hasPointer = {cursor: "pointer"};

    function navClick(direction){
        let currExtNum;
        if(direction === "next" && itemList!=null){
            currExtNum = (currentItem + 1 >= itemList.length) ? 0 : (currentItem + 1);
            setCurrentItem(currExtNum);
        }
        if(direction === "prev" && itemList!=null){
            currExtNum = currentItem === 0 ? (itemList.length - 1) : (currentItem - 1);
            setCurrentItem(currExtNum);
        }
    }

    useEffect(()=>{
        itemList.forEach((item)=>{
            const img = new Image();
            img.src = item.src;
        });

        const myInterval = setTimeout(() => {
            navClick("next");
        }, 9000);

        return () => clearTimeout(myInterval);
    },[]);

    useEffect(()=>{
        const myInterval2 = setTimeout(() => {
            navClick("next");
        }, 9000);

        return () => clearTimeout(myInterval2);
    },[currentItem])

    return(
        <>
            <div key={currentItem} className="home-item">
                <div className="home-item-title">
                    <div className="home-item-title-cont">
                        <PrevMobile prev={()=>{
                            navClick("prev");
                            }} />
                        <h2>{itemList[currentItem].texte}</h2>
                        <NextMobile next={()=>{
                            navClick("next");
                            }} />
                    </div>
                </div>
                <a 
                    className="home-item-url" 
                    href={itemList[currentItem].url}
                    style={itemList[currentItem].url!=="" ? hasPointer: {}}
                    title={itemList[currentItem].url}>
                    <img 
                        className="home-item-img" 
                        alt={"Photo " + itemList[currentItem].texte}
                        src={itemList[currentItem].src} 
                        />
                </a>
            </div>
            <NavButtons 
                next={()=>{navClick("next")}} 
                prev={()=>{navClick("prev")}}/>
        </>
        );
}

export default function HomeGallery(){
    const [homeListe, setHomeListe] = useState(null);

    useEffect(()=>{
        getGallerie();
    },[]);

    function getGallerie(){
        axios.get(API_PATH + "home/read.php?t="+Date.now(), 
        {headers: 
            {'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'}
        })
        .then((res)=>{
           setHomeListe(res.data); 
        })
        .catch((err)=>console.log(err));
    }

    return(
        <div id="home-gallery-cont">
            {homeListe && <HomeGalleryInner itemList={homeListe} />}
        </div>
    );
}