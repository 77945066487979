import React from "react";
import Groupe from "../components/Groupe";

import MobileFooter from "../components/MobileFooter";


export default function GroupePage(){
    return(
        <>
            <aside className="titre-page-aside desktop-only">
                <img src="https://media.vancouver-live.fr/site/groupe/Groupe_titre.svg" alt="Titre de page Groupe" />
            </aside>
            <Groupe />
            <MobileFooter />
        </>
    );
}