import React, {useState} from "react";
import Extraits from "../components/Extraits";
import { PhotoGalleryDesktop, PhotoGalleryMobile } from "../components/PhotoGalleryButtons";
import MobileFooter from "../components/MobileFooter";
import VideoGallery from "../components/VideoGallery";

import '../css/mediasPage.css';
import GalleryModal from "../components/GalleryModal";


export default function MediaPage(){
    const [modalOpen, setModalOpen] = useState(false);

    function openCloseModal(){
        setModalOpen((modalOpen === false)? true : false);
    }

    return(
        <>
            <aside 
                className="titre-page-aside desktop-only">
                <img
                    style={{height: "calc(0.23 * 100vw)"}} 
                    src="https://media.vancouver-live.fr/site/medias/Medias_titre.svg" alt="Titre de page Medias" />
                <PhotoGalleryDesktop openerFunc={()=>openCloseModal()} open={modalOpen} />
            </aside>
            <VideoGallery />
            <PhotoGalleryMobile openerFunc={()=>openCloseModal()} />
            <Extraits additionalClasses="extrait-gallery-custom" />
            <GalleryModal open={modalOpen} closingFunc={()=>setModalOpen(false)}/>
            <MobileFooter />
        </>
    );
}