const groupeContent = {
    default: {   
        id: 0,
        nom: 'musiciens',
        role: 'Pour en savoir plus : sélectionnez un musicien\xa0!',
        texte: `Vancouver, ce sont 6 musiciens aux profils complémentaires, liés par l’amitié et la passion musicale.
                Notre projet est de faire revivre les grandes heures de la pop des 60’s et des 70’s, et leurs dignes
                héritiers. Avec sérieux et bonne humeur, nous cultivons l’authenticité du son vintage et le travail des
                harmonies vocales.`,
        aside: `<em>Année de création</em>
                    2013
                <em>Musiciens</em>
                    6
                <em>Département</em>
                    Val d’Oise
                <em>Répertoire</em>
                    Reprises pop rock 60’s et 70’s
                <em>Nombre de titres</em>
                    Plus de 60
                <em>Moyens techniques</em>
                    Sono façade et retours (RCF / Alto) pour 200 personnes, éclairages scéniques.
                <em>Disponible pour</em>
                    soirées privées, événements publics et d’entreprise, participation à des événements caritatifs…
                <em>Zone d’intervention</em>
                    Région parisienne et au-delà sur demande`,
        image: "https://media.vancouver-live.fr/site/groupe/Groupe_photoGroupe.png"
    },
    seb: {   
        id: 1,
        nom: 'seb',
        role: 'Batterie',
        texte: `Il aime le son qui tape fort, de Megadeth à Sepultura en passant par Dying Fetus, 
                c’est donc à la base un adepte du métal… avec Vancouver il a découvert la douceur folk et la fraîcheur pop… 
                tout en gardant sa belle énergie\xa0!`,
        aside: `<em>Influences principales</em>
                    Hard Rock, Punk, Rock et un petit peu plus bourrin (Death, Carcass, Mercyless, No Return, Noir
                    Desir, Bad Religion...)
                <em>Matériel utilisé</em>
                    Batterie électronique Roland TD-25, acoustique Tama Superstar et Pearl Forum avec peaux Remo Pinstrap, crash
                    Zildjian, Hit-Hat et Ride Sabian
                <em>Titres préférés</em>
                    The Importance Of Being Idle, Beggin, Space Oddity, Pinball Wizard, Helter Skelter
                <em>Albums préférés</em> 
                    The Number Of The Beast (Iron Maiden), The Sound Of Perseverance (Death), Colored Funeral
                    (Mercyless), Heartwork (Carcass)`,
        image: "https://media.vancouver-live.fr/site/groupe/Groupe_photoSeb.png"
    },
    manu: {   
        id: 2,
        nom: 'manu',
        role: 'Guitare, chœurs, claviers',
        texte: `Il est le membre fondateur du groupe. Pianiste de formation classique, puis guitariste, 
                il a fait partie du groupe Méoline pendant dix ans, ce qui l’a amené à jouer notamment au Zenith de Nancy et au Galaxie d’Amnéville. 
                En 2013, il décide de fonder une nouvelle formation dans le Val d’Oise où il travaille désormais.`,
        aside: `<em>Influences principales</em>
                    Marillion, Queen, Radiohead, Beatles, Dire Straits, K's Choice
                <em>Matériel utilisé</em>
                    Guitares acoustique ovation et électrique Gibson SG standard et Fender Telecaster Thinline.
                    Multi effet Mooer GE150 (simulateur ampli Vox AC30). Clavier Studiologic. VST : Hamond B3 (Apple) Piano droit The
                    Gentleman (Native Instruments)
                <em>Titres préférés</em>
                    Here Come The Sun, Alright, Space Oddity
                <em>Albums préférés</em> 
                    The Dark Side of the Moon (Pink Floyd), OK Computer (Radiohead), A Night at the Opera (Queen), Figurines (eNola), Harvest (Neil Young)`,
        image: "https://media.vancouver-live.fr/site/groupe/Groupe_photoManu.png"
    },
    antoine: {   
        id: 3,
        nom: 'antoine',
        role: 'Basse, chœurs',
        texte: `A la base, Antoine est guitariste, profondément influencé par King Crimson et son leader Robert Fripp, 
                et adepte des Crafty Guitaristes, formations dédiées exclusivement aux guitares. 
                Mais le projet de groupe porté par Manu l’intéresse et c’est tout naturellement qu’il s'est converti à la basse.`,
        aside: `<em>Influences principales</em>
                    King Crimson, Pink Floyd, Stevie Ray Vaughan, Bob Marley
                <em>Matériel utilisé</em>
                    <i>Basse électrique</i> : Fender Precision 4 cordes, Fender Jazz Bass 5 cordes<br>
                    <i>Ampli</i> : Markbass
                <em>Titres préférés</em>
                    Superstition
                <em>Albums préférés</em> 
                    In the Court of the Crimson King (King Crimson), White Album / Sgt. Pepper / Abbey Road (The Beatles).`,
        image: "https://media.vancouver-live.fr/site/groupe/Groupe_photoAntoine.png"
        
    },
    xavier: {   
        id: 4,
        nom: 'xavier',
        role: 'Guitare, chœurs',
        texte: `Soliste précis et efficace au feeling blues-rock, il apporte au groupe une bonne dose d'électricité ! 
                Comme en témoigne son pedalboard, Xav est un amoureux du son. 
                Après avoir roulé sa bosse au sein de nombreux groupes, du sud de la France au Sénégal, 
                il a posé ses valises dans le Val d’Oise... et son ampli à Vancouver.`,
        aside: `<em>Influences principales</em>
                    BB King, Albert King, Stevie Ray Vaughan, Eric Clapton, Rory Gallagher, Neil Young, David Gilmour,
                    Jimmy Page, Jeff Beck, Pink Floyd, Led Zepplin, Beatles, ZZ Top
                <em>Matériel utilisé</em>
                    <i>Amplis</i> : Silvertone 1482, Vox AC15<br>
                    <i>Guitares électriques</i> : Greco Telecaster, Burny SG, Fender Telecaster, Ibanez
                    AR2619 av prestige violin antique<br>
                    <i>Guitares acoustiques</i> : Art & Lutherie Roadhouse havana brown, Martin & CO GPCPA5<br>
                    <i>Pédales</i> : Plein ou trop, chacun son point de vue&nbsp;!
                <em>Titres préférés</em>
                    Helter Skelter, Pinball Wizzard, Time, I Want You, While My Guitar Gently Weeps
                <em>Albums préférés</em> 
                    The Dark Side Of The Moon (Pink Floyd) White album, Abbey Road, Sgt. Pepper (Beatles) Couldn’t
                    Stand The Weather (Stevie Ray Vaughan)`,
        image: "https://media.vancouver-live.fr/site/groupe/Groupe_photoXavier.png"
        
    },
    marion: {   
        id: 5,
        nom: 'marion',
        role: 'Violon, chœurs, percussions…',
        texte: `Venue au départ pour une collaboration ponctuelle, 
                Marion est vite devenue le complément indispensable au son du groupe par ses interventions au violon électrique, 
                aux chœurs, mais aussi à la flûte et aux percussions...`,
        aside: `<em>Influences principales</em>
                    JS Bach, Vivaldi, Liz Caroll, Fleetwood Mac, Supertramp, Dire Straits, Pink Floyd, Ibrahim Maalouf
                <em>Matériel utilisé</em>
                    Violon électrique Yamaha SV250, Archet carbone, Multieffets Boss GT-6, Alesis Samplepad 4, Tambourin Stagg
                <em>Titres préférés</em>
                    Ain’t No Sunshine, Come Together, Day Tripper, Long Train Running, Wish You Where Here, Old Man
                <em>Albums préférés</em> 
                    Rumours (Fleewood Mac), Crime Of The Century (Supertramp), The Wall (Pink Floyd), Illusions (Ibrahim
                    Maalouf)`,
        image: "https://media.vancouver-live.fr/site/groupe/Groupe_photoMarion.png"
    },
    fred: {   
        id: 6,
        nom: 'fred',
        role: 'Chant, clavier',
        texte: `Habitué des groupes de reprises, c’est en répondant à une petite annonce qu’il rencontre les membres de Vancouver. 
                Egalement auteur-compositeur (chanson française, electro, pop) il cultive avec Vancouver le plaisir d'interpréter et de jouer en groupe. 
                Son timbre reconnaissable contribue à la signature sonore du groupe, qu’il enrichit également de ses interventions aux claviers.`,
        aside: `<em>Influences principales</em>David Bowie, Eels, Metronomy, Sufjan Stevens
                <em>Matériel utilisé</em>Micro HF Beyerdynamic, clavier Studiologic, MacBook Pro, Mainstage. VST : Fender Rhodes (Apple), Vox
                Continental, Farfisa Compact, Moog, Prophet 5, Mellotron (Arturia).
                <em>Titres préférés</em>Ashes To Ashes, Summertime, Time, Lucy In The Sky
                <em>Albums préférés </em> Diamond Dogs (David Bowie), Revolver (Beatles), Wish You Where Here (Pink Floyd), Illinois
                (Sufjan Stevens), Ok Computer (Radiohead), Blue Lines (Massive Attack)`,
        image: "https://media.vancouver-live.fr/site/groupe/Groupe_photoFred.png"
    } 

};

export default groupeContent;