import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import API_PATH from "../config/API_PATH";

import ExtraitModal from "./ExtraitModal";

import './css/setlist.css';




function DisplaySetlist(props){
    const [anneeListe, setAnneeListe] = useState();
    const [categorieABCListe, setCategorieABCListe] = useState(null);

    const [currentSort, setCurrentSort] = useState({index: null, value: null}); 

    const CSSAnnee = "#f5b0a1";
    const CSSABC = "#85a6c7";
    const styleCSS = props.mode==="annee"? "nav-annee" : "nav-ABC";

    const vinylElem = document.getElementById("setlist-vinyl-disc");

    /* INIT */
    useEffect(()=>{
        if(props.mode === "annee"){
            const liste =  getAnneeList();
            setAnneeListe(liste);
            setCurrentSort({index: 0, value: liste[0]});
        }else if(props.mode==="categorieABC"){
            const liste = getCategorieABCList();
            setCategorieABCListe(liste);
            setCurrentSort({index: 0, value: liste[0]});
        }
    },[]);

    /* CHANGEMEMNT DE MODE */
    useEffect(()=>{
        if(props.mode === "annee"){
            const liste =  getAnneeList();
            setAnneeListe(liste);
            setCurrentSort({index: 0, value: liste[0]});
        }else if(props.mode==="categorieABC"){
            const liste = getCategorieABCList();
            setCategorieABCListe(liste);
            setCurrentSort({index: 0, value: liste[0]});
        }
    },[props.mode]);

    function playInOut(){
        vinylElem.classList.add('play-in-out');
        setTimeout(()=>{
            vinylElem.classList.remove('play-in-out');
        }, 500);
    }

    function nextSort(){
        const currIndex = currentSort.index;
        if(props.mode === "annee"){
            const nextIndex = (currIndex === (anneeListe.length-1))? 0 : currIndex+1;
            setCurrentSort({index: nextIndex, value: anneeListe[nextIndex]});
        }else{
            const nextIndex = (currIndex === (categorieABCListe.length-1))? 0 : currIndex+1;
            setCurrentSort({index: nextIndex, value: categorieABCListe[nextIndex]});
        }

        playInOut();
    }

    function prevSort(){
        const currIndex = currentSort.index;
        if(props.mode==="annee"){
            const nextIndex = (currIndex === 0)? anneeListe.length-1 : currIndex-1;
            setCurrentSort({index: nextIndex, value: anneeListe[nextIndex]});
        }else{
            const nextIndex = (currIndex === 0)? categorieABCListe.length-1 : currIndex-1;
            setCurrentSort({index: nextIndex, value: categorieABCListe[nextIndex]});
        }

        playInOut();
    }

    function getAnneeList(){

        const fullListe = props.setlist.map((item)=>{
            let annee;
            if(item.annee < 1980){
                annee = item.annee;
            }else if(item.annee >= 1980 && item.annee < 2000){
                annee = "80's et 90's";
            }else if(item.annee >= 2000){
                annee = "2000 +";
            }
            return annee
        });
        
        let liste = fullListe.filter((item, pos)=>{
            return fullListe.indexOf(item) === pos;
        });

        liste = liste.sort();

        const i = liste.indexOf("80's et 90's");
        const y = liste.indexOf("2000 +");

        if(i !== -1 && y !== -1){
           liste[i] = "2000 +";
           liste[y] = "80's et 90's";
        }

        return liste;
    }

    function getCategorieABCList(){

        const fullListe = props.setlist.map((item)=>item.categorieABC);
        
        let liste = fullListe.filter((item, pos)=>{
            return fullListe.indexOf(item) === pos;
        });

        liste = liste.sort();

        return liste;
    }

    function RenderTracks(){
        let displayedTracks;
        let displayedArtists;
        let render;
        let keyArtiste = 0;

        /* MODE ANNEE */
        if(props.mode === "annee"){
            displayedTracks = props.setlist.filter((item)=>{
                if((item.annee === currentSort.value) ||
                    ((item.annee >= 1980 && item.annee < 2000) && currentSort.value === "80's et 90's") ||
                    ((item.annee >= 2000) && currentSort.value === "2000 +")){
                    return item;
                }
            });
            const getArtistes = displayedTracks.map((item)=>item.artiste)
            displayedArtists = getArtistes.filter((item, pos)=>{
                return getArtistes.indexOf(item)===pos;
            });
        }
        /* MODE ALPHABETIQUE */
        else{
            displayedTracks = props.setlist.filter((item)=>{
                if(item.categorieABC === currentSort.value){
                    return item;
                }
            });
            const getArtistes = displayedTracks.map((item)=>item.artiste)
            displayedArtists = getArtistes.filter((item, pos)=>{
                return getArtistes.indexOf(item)===pos;
            });
        }
        
        render = displayedArtists.map((artiste)=>{
            keyArtiste++;
            const trackList = displayedTracks.map((track)=>{
                if(track.artiste === artiste){
                    return(
                        <span key={track.id} className="setlist-track">
                            {track.titre}
                            {track.src && <ExtraitModal track={track} />}
                        </span>
                    );
                }
            });
            
            return(
                <div key={"groupe"+keyArtiste} className="setlist-group">
                    <span key={"artiste"+keyArtiste} className="setlist-artiste-name">{artiste}</span>
                    {trackList}
                </div>
            )
        });

        return(
            <>
                {render}
            </>
        );
    }

    return(
        <>
            <div
                className={styleCSS}
                id="setlist-nav">
                <div className="setlist-nav-top">
                    <button
                        className="setlist-nav-button"
                        onClick={prevSort}>
                        <svg version="1.1" id="ButtonPrev" xmlns="https://www.w3.org/2000/svg" x="0px" y="0px"
                            viewBox="0 0 43.2 43.2">
                            <circle
                                style={{ fill: props.mode === "annee" ? CSSAnnee : CSSABC }}
                                cx="21.6" cy="21.6" r="21.6" />
                            <path style={{ fill: "white" }} d="M10.3,21.1l11.8-10.8c0.7-0.6,1.7-0.1,1.7,0.8v21.6c0,0.9-1.1,1.4-1.7,0.8L10.3,22.6
	                            C9.8,22.2,9.8,21.5,10.3,21.1z"/>
                            <g>
                                <defs>
                                    <circle id="SVGID_1_" cx="21.8" cy="21.6" r="21.6" />
                                </defs>
                                <clipPath id="SVGID_00000104672563010744330740000012072514909066444935_">
                                    <use />
                                </clipPath>
                                <rect style={{ fill: "white" }} x="21.8" y="17.8" width="23.2" height="8" />
                            </g>
                        </svg>
                    </button>
                    {currentSort.value}
                    <button
                        className="setlist-nav-button"
                        onClick={nextSort}>
                        <svg version="1.1" id="ButtonNext" xmlns="https://www.w3.org/2000/svg" x="0px" y="0px"
                            viewBox="0 0 43.2 43.2">
                            <circle
                                style={{ fill: props.mode === "annee" ? CSSAnnee : CSSABC }}
                                cx="21.6" cy="21.6" r="21.6" />
                            <path style={{ fill: "white" }} d="M33.1,22.1L21.3,32.9c-0.7,0.6-1.7,0.1-1.7-0.8V10.5c0-0.9,1.1-1.4,1.7-0.8l11.8,10.8
	                                C33.6,21,33.6,21.7,33.1,22.1z"/>
                            <g>
                                <defs>
                                    <circle id="SVGID_1_" cx="21.8" cy="21.6" r="21.6" />
                                </defs>
                                <clipPath id="SVGID_00000104672563010744330740000012072514909066444935_">
                                    <use />
                                </clipPath>
                                <rect style={{ fill: "white" }} x="-1.6" y="17.4" transform="matrix(-1 -4.484780e-11 4.484780e-11 -1 20 42.739)" width="23.2" height="8" />
                            </g>
                        </svg>
                    </button>
                </div>
                <div className="setlist-nav-separator"></div>
            </div>
            
            <div id="setlist-cont">
                <div id="setlist-window">
                    <RenderTracks />
                </div>
            </div>
        </>);
}

export default function Setlist(props){
    const [sortMode, setSortMode] = useState("categorieABC");
    const refAnnee = useRef(null);
    const refABC = useRef(null);
    const refSeparatorTop = useRef(null);

    const vinylElem = document.getElementById("setlist-vinyl-disc");

    const [setlist, setSetlist] = useState(null);

    useEffect(()=>{
       getSetlist();
       //setSetlist(TestSetlist);
    },[])

    function getSetlist(){
        axios.get(API_PATH + "setlist/read.php?t="+Date.now(), 
        {headers: 
            {'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'}
        })
        .then((res)=>{
           setSetlist(res.data); 
        })
        .catch((err)=>console.log(err));
    }

    function switchSortMode(e, mode, unfocus){
        e.target.classList.remove('not-focused');
        unfocus.current.classList.add('not-focused');
        refSeparatorTop.current.className = (mode === "annee")? "setlist-separator-annee" : "setlist-separator-ABC";

        if(sortMode !== mode){
            playSpin();
        }

        setSortMode(mode);

    }

    function playSpin(){
        vinylElem.classList.add('play-spin');
        setTimeout(()=>{
            vinylElem.classList.remove('play-spin');
        }, 600);
    }


    return(
        <section id="setlist" className="comp-center">
            <div id="setlist-sort">
                <div id="setlist-sort-tab">
                    <div
                        ref={refAnnee}
                        className="setlist-sort-tab setlist-sort-annee not-focused"
                        onClick={(e) => {
                            switchSortMode(e, "annee", refABC);
                            props.switchZoom();
                        }}>
                        Par années
                    </div>
                    <div
                        ref={refABC}
                        className="setlist-sort-tab setlist-sort-ABC"
                        onClick={(e) => {
                            switchSortMode(e, "categorieABC", refAnnee);
                            props.switchZoom();
                        }}>
                        Par artistes
                    </div>
                </div>
                <div ref={refSeparatorTop} id="setlist-separator-cont" className="setlist-separator-ABC">
                    <div id="separator1" className="setlist-separator"></div>
                    <div id="separator2" className="setlist-separator"></div>
                    <div id="separator3" className="setlist-separator"></div>
                </div>
            </div>

            {setlist && <DisplaySetlist setlist={setlist} mode={sortMode} />}

        </section>);
}
