import React from "react";
import "./css/zoom.css";


export default function Zoom(props) {
    return (
        <>
            {props.item && <section id="zoom" key={props.item.id}>
                <div id="zoom-header">
                    <img id="zoom-logo" src="https://media.vancouver-live.fr/site/setlist/Zoom.svg" alt="Logo de l'encadré Zoom "/>
                    <img id="zoom-cover" src={props.item.src} alt={"Pochette de l'album contenant " + props.item.titre} ></img>
                </div>
                <div id="zoom-body">
                    <h3 id="zoom-artiste">{props.item.artiste}</h3>
                    <h3 id="zoom-titre">{props.item.titre} <span id="zoom-annee">{props.item.annee}</span></h3>
                    <p id="zoom-texte">
                        {props.item.texte}
                    </p>
                </div>
            </section>}
        </>
    );
}