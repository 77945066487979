import React, {useEffect, useState} from "react";
import './css/groupe.css';
import groupeContent from "../content/groupeContent";
//import ReactHtmlParser from 'react-html-parser';

export default function Groupe(){
    const [currentSelectedMember, setCurrentSelectedMember] = useState("default");

    const listBandMembers = ["default", "xavier", "antoine", "manu", "seb", "marion", "fred"];

    useEffect(()=>{
        listBandMembers.forEach((item)=>{
            const img = new Image();
            img.src = groupeContent[item].image;
            //console.log(groupeContent[item].image);
        });
        /* precharge silhouettes */
        const xav=new Image();
        xav.src="https://media.vancouver-live.fr/site/groupe/Groupe_Xavier.png";
        const seb=new Image();
        seb.src="https://media.vancouver-live.fr/site/groupe/Groupe_Seb.png";
        const manu=new Image();
        manu.src="https://media.vancouver-live.fr/site/groupe/Groupe_Manu.png";
        const antoine=new Image();
        antoine.src="https://media.vancouver-live.fr/site/groupe/Groupe_Antoine.png";
        const marion=new Image();
        marion.src="https://media.vancouver-live.fr/site/groupe/Groupe_Marion.png";
        const fred=new Image();
        fred.src="https://media.vancouver-live.fr/site/groupe/Groupe_Fred.png";
    },[]);


    function Aside(props){
        const textAside = groupeContent[currentSelectedMember].aside;
        const displayClass = props.mobile === "true" ? "mobile-only-block" : "desktop-only";
        const setId = props.mobile === "true" ? "groupe-aside-mobile" : "groupe-aside";

        return(
            <div id={setId} className={displayClass}>
                <p
                    dangerouslySetInnerHTML={{__html: textAside}}>
                </p>
            </div>
        );
    }

    function TextContent(){
        return(
            <div id="groupe-text-content">
                    <h2>{groupeContent[currentSelectedMember].nom}</h2>
                    <h3>{groupeContent[currentSelectedMember].role}</h3>
                    <p>{groupeContent[currentSelectedMember].texte}</p>
            </div>
        );
    }

    function Picture(){
        return(
            <div id="groupe-image-side" className="desktop-only">
                <img 
                    src={groupeContent[currentSelectedMember].image}
                    alt={currentSelectedMember!=="default"? "photo  "+currentSelectedMember: "photo du groupe"}
                />
            </div>
        )

    }

    function getMember(e){
        const selectedId = e.target.id;
        setCurrentSelectedMember(selectedId);
    }

    function returnDefault(){
        setCurrentSelectedMember("default");
    }

    function GroupeSelector(){
        return(
            <div id="groupe-selector-outer">
                <div 
                    title="retour"
                    className="selector-default"
                    onClick={returnDefault}>
                </div>
                <div id="groupe-selector">
                    <img 
                        id="xavier"
                        alt="Silhouette Xavier"
                        className={currentSelectedMember==='xavier'?"selected":"greyed-out"}
                        src="https://media.vancouver-live.fr/site/groupe/Groupe_Xavier.png"
                        onClick={getMember}
                    />
                    <img 
                        id="antoine"
                        alt="Silhouette Antoine" 
                        className={currentSelectedMember==='antoine'?"selected":"greyed-out"}
                        src="https://media.vancouver-live.fr/site/groupe/Groupe_Antoine.png"
                        onClick={getMember}
                    />
                    <img 
                        id="manu"
                        alt="Silhouette Manu"
                        className={currentSelectedMember==='manu'?"selected":"greyed-out"}
                        src="https://media.vancouver-live.fr/site/groupe/Groupe_Manu.png"
                        onClick={getMember}
                    />
                    <img 
                        id="seb"
                        alt="Silhouette Seb"
                        className={currentSelectedMember==='seb'?"selected":"greyed-out"}
                        src="https://media.vancouver-live.fr/site/groupe/Groupe_Seb.png"
                        onClick={getMember}
                    />
                    <img 
                        id="marion"
                        alt="Silhouette Marion"
                        className={currentSelectedMember==='marion'?"selected":"greyed-out"}
                        src="https://media.vancouver-live.fr/site/groupe/Groupe_Marion.png"
                        onClick={getMember}
                    />
                    <img 
                        id="fred" 
                        alt="Silhouette Fred"
                        className={currentSelectedMember==='fred'?"selected":"greyed-out"}
                        src="https://media.vancouver-live.fr/site/groupe/Groupe_Fred.png"
                        onClick={getMember}
                    />
                </div>
                <div 
                    title="retour"
                    className="selector-default"
                    onClick={returnDefault}>
                </div>
            </div>
        );
    }

    return(
        <>
            <Aside />
            <div id="groupe-main">
                <GroupeSelector />
                <TextContent />
            </div>
            <Aside mobile="true" />
            <Picture />
        </>
    );
}
